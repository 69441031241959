import { CHAIN_IDS } from "enums/chainIds";

import { EXTERNAL_ROUTES } from "config/routes";

interface Collection {
  name: string;
  url: {
    [key in CHAIN_IDS]?: {
      base: string;
      all?: string;
    };
  };
  bundles?: {
    name: string;
    value: string;
  }[];
}

export const collections: Collection[] = [
  {
    name: "$HOOP",
    url: {
      [CHAIN_IDS.BSC]: {
        base: "https://opensea.io/collection/hoop-collection-bsc",
      },
    },
  },
  {
    name: "The Polacy NFT",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: "https://opensea.io/collection/the-polacy-nft",
      },
    },
  },
  {
    name: "Bigshortbets NFT",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: "https://opensea.io/collection/bigsb-1000",
      },
    },
  },
  {
    name: "ADSHARES",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/adshares-af",
      },
    },
  },
  {
    name: "Pop Art Cats Wearables",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `https://opensea.io/collection/poparts-uniqly`,
      },
    },
  },
  {
    name: "Sergito Phygital Drop",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/sergito-phygital-drop",
      },
    },
  },
  {
    name: "Street Melts",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/vandul-shop-1",
      },
    },
  },
  {
    name: "The Ethereans",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `https://opensea.io/collection/the-ethereans-v2-1`,
      },
    },
  },
  {
    name: "Scholarz",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/scholarz-collection-1",
      },
    },
  },
  {
    name: "GOATz Merch",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/goatzmerch",
      },
    },
  },
  {
    name: "Long Lost",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/long-lost-drop",
      },
    },
  },
  {
    name: "Crypto Banter",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `https://opensea.io/collection/crypto-banter-x-uniqly`,
      },
    },
  },
  {
    name: "Wizarre",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/wizarre",
      },
    },
  },
  {
    name: "SupDucks",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=SupDucks`,
      },
    },
    bundles: [
      {
        name: "Bath Time Tee",
        value:
          "&search[stringTraits][1][name]=Color&search[stringTraits][1][values][0]=White&search[stringTraits][2][name]=Physical%20item&search[stringTraits][2][values][0]=T-SHIRT",
      },
      {
        name: "Webb3d Feet Hoodie",
        value:
          "&search[stringTraits][1][name]=Color&search[stringTraits][1][values][0]=Black&search[stringTraits][2][name]=Physical%20item&search[stringTraits][2][values][0]=HOODIE",
      },
      {
        name: "Sup Hat",
        value:
          "&search[stringTraits][1][name]=Physical%20item&search[stringTraits][1][values][0]=HAT",
      },
      {
        name: "Get Off Tee",
        value:
          "&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Get%20Off",
      },
    ],
  },
  {
    name: "GhostsProject",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/ghostproject-x-uniqly-io",
      },
    },
  },
  {
    name: "APEFUR COLLECTION",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/divico-collection",
      },
    },
  },
  {
    name: "Vuzec",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: "https://opensea.io/collection/vuzec-collection",
      },
    },
  },
  {
    name: "The Metakey",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `https://opensea.io/collection/metakey-genesis-merch`,
      },
    },
    bundles: [
      {
        name: "The Logo",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Color&search[stringTraits][1][values][0]=Black&search[stringTraits][2][name]=Eligibility&search[stringTraits][2][values][0]=Unlimited&search[stringTraits][3][name]=Physical%20item&search[stringTraits][3][values][0]=T-SHIRT",
      },
      {
        name: "The Metakey",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Physical%20item&search[stringTraits][1][values][0]=LONG%20SLEEVE",
      },
      {
        name: "The Asterius",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Physical%20item&search[stringTraits][1][values][0]=HOODIE&search[stringTraits][2][name]=Color&search[stringTraits][2][values][0]=Black",
      },
      {
        name: "The Metakrew",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=MetaKrew",
      },
      {
        name: "The Keymaster",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=KeyMaster",
      },
      {
        name: "The Captain",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=Edition%204",
      },
      {
        name: "The Ultimate",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=Edition%203",
      },
      {
        name: "The Artifact",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=Edition%202",
      },
      {
        name: "The Genesis",
        value:
          "?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=MetaKey&search[stringTraits][1][name]=Eligibility&search[stringTraits][1][values][0]=Genesis",
      },
    ],
  },
  {
    name: "More Than Gamers",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=More%20Than%20Gamers`,
      },
    },
  },
  {
    name: "Axolittles",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: "https://opensea.io/collection/uniqly-x-axolittles-collection",
      },
    },
  },
  {
    name: "Peaceful Groupies",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: "https://opensea.io/collection/uniqly-x-peaceful-groupies-collection",
      },
    },
    bundles: [
      {
        name: "Salty Candy Shirt",
        value:
          "?search[sortAscending]=true&search[stringTraits][0][name]=Trait&search[stringTraits][0][values][0]=Salty%20Candy",
      },
      {
        name: "The Chip Shirt",
        value:
          "?search[sortAscending]=true&search[stringTraits][0][name]=Trait&search[stringTraits][0][values][0]=Chip",
      },
      {
        name: "Futura Children Shirt",
        value:
          "?search[sortAscending]=true&search[stringTraits][0][name]=Trait&search[stringTraits][0][values][0]=Futura%20Children",
      },
      {
        name: "Sprinkles Hoodie",
        value:
          "?search[sortAscending]=true&search[stringTraits][0][name]=Trait&search[stringTraits][0][values][0]=Sprinkles",
      },
      {
        name: "PEACEVOID Cap",
        value:
          "?search[sortAscending]=true&search[stringTraits][0][name]=Trait&search[stringTraits][0][values][0]=Peacevoid",
      },
    ],
  },
  {
    name: "Uniqly x Bullieverse collection",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Partner&search[stringTraits][0][values][0]=Bullieverse`,
      },
    },
  },
  {
    name: "Uniqly Valentines",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET}uniqly-valentines`,
      },
    },
  },
  {
    name: "ZenApe",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=ZenApe%20Monke%20Collection&search[stringTraits][0][values][0]=Monke%20T-shirt&search[stringTraits][0][values][1]=Monke%20Hoodie`,
      },
    },
  },
  {
    name: "HYVE",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Hyve&search[stringTraits][0][values][0]=Classic%20Hoodie&search[stringTraits][0][values][1]=Classic%20T-shirt`,
      },
    },
  },
  {
    name: "Axie Sisters",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Axie%20Sisters&search[stringTraits][0][values][0]=NFT%20Hoodie`,
      },
    },
  },
  {
    name: "Ulti Arena",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Ulti%20Arena%20Classic&search[stringTraits][0][values][0]=Ulti%20Army&search[stringTraits][0][values][1]=Ulti%20Ninja`,
      },
    },
  },
  {
    name: "Hololoot",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Hololoot&search[stringTraits][0][values][0]=Blue%20Swag&search[stringTraits][0][values][1]=Classic%20Black&search[stringTraits][0][values][2]=Purple%20Swag`,
      },
    },
  },
  {
    name: "Uniqly",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Uniqly&search[stringTraits][0][values][0]=Also%20in%20the%20Metaverse`,
      },
    },
  },
  {
    name: "CyberKongz Classic",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[collections][0]=uniqly-collections&search[resultModel]=ASSETS&search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=CyberKongz%20Classic`,
        all: "&search[stringTraits][0][values][0]=CK%20Banana%20Hoodie&search[stringTraits][0][values][1]=CyberKongz%20Logo%20Shirt&search[stringTraits][0][values][2]=CK%20Hoodie&search[stringTraits][0][values][3]=CyberKongz%20Logo%20Hoodie&search[stringTraits][0][values][4]=CK%20Banana%20Shirt&search[stringTraits][0][values][5]=CK%20Banana",
      },
    },
    bundles: [
      {
        name: "CK Banana Hoodie",
        value: "&search[stringTraits][0][values][0]=CK%20Banana%20Hoodie",
      },
      {
        name: "CyberKongz Logo Shirt",
        value: "&search[stringTraits][0][values][1]=CyberKongz%20Logo%20Shirt",
      },
      {
        name: "CK Hoodie",
        value: "&search[stringTraits][0][values][2]=CK%20Hoodie",
      },
      {
        name: "CyberKongz Logo Hoodie",
        value: "&search[stringTraits][0][values][3]=CyberKongz%20Logo%20Hoodie",
      },
      {
        name: "CK Banana Shirt",
        value:
          "&search[stringTraits][0][values][4]=CK%20Banana%20Shirt&search[stringTraits][0][values][5]=CK%20Banana",
      },
    ],
  },
  {
    name: "Cagyjan",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Cagyjan&search[stringTraits][0][values][0]=No%20NFT%2C%20No%20Plays`,
      },
    },
  },
  {
    name: "Jan Błachowicz",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `https://opensea.io/collection/subme-x-uniqly-io`,
      },
    },
  },
  {
    name: "CryptoStache",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=CryptoStache&search[stringTraits][0][values][0]=Mustache%20Love&search[stringTraits][0][values][1]=Play%20To%20Earn&search[stringTraits][0][values][2]=Secret%20Agent%20Stache`,
      },
    },
  },
  {
    name: "Virtus.Pro",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=VirtusPro&search[stringTraits][0][values][0]=GoodGame&search[stringTraits][0][values][1]=Aimbot%20Activated&search[stringTraits][0][values][2]=360°%20noscope&search[stringTraits][0][values][3]=AWP%20on%20Mid&search[stringTraits][0][values][4]=Eco&search[stringTraits][0][values][5]=Headshot`,
      },
    },
  },
  {
    name: "NFT.NYC",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=NFT.NYC&search[stringTraits][0][values][0]=NFT.NYC%202021`,
      },
    },
  },
  {
    name: "Brycent",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Brycent&search[stringTraits][0][values][0]=AurayaBoost`,
      },
    },
  },
  {
    name: "Satoshi Stacker",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Satoshi%20Stacker&search[stringTraits][0][values][0]=Digital%20Satoshi&search[stringTraits][0][values][1]=Universe&search[stringTraits][0][values][2]=BSC%20Stacker&search[stringTraits][0][values][3]=Gambler`,
      },
    },
  },
  {
    name: "El Crypto Chapo",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=El%20Crypto%20Chapo&search[stringTraits][0][values][0]=Diamond%20Hodler&search[stringTraits][0][values][1]=Beach&search[stringTraits][0][values][2]=De%20La%20Muerte`,
      },
    },
  },
  {
    name: "Nonfungible Fashion [by NFThub]",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=NFTHub&search[stringTraits][0][values][0]=Style%3A%20Trash%20a%20Hallelujah&search[stringTraits][0][values][1]=Artist%3A%20BruceTheGoose&search[stringTraits][0][values][2]=Artist%3A%20DrawberryMilkTea&search[stringTraits][0][values][3]=Style%3A%20I%20Heart%20NFT&search[stringTraits][0][values][4]=Artist%3A%20Marcotic&search[stringTraits][0][values][5]=Style%3A%20Diamond%20Hands&search[stringTraits][0][values][6]=Style%3A%20Killer%20Bun&search[stringTraits][0][values][7]=Artist%3A%20Magusz%20%26%20Natural%20Warp&search[stringTraits][0][values][8]=Artist%3A%20hlichaa&search[stringTraits][0][values][9]=Style%3A%20Dragons%20%22N%22%20Kings&search[stringTraits][0][values][10]=Artist%3A%20Magusz&search[stringTraits][0][values][11]=Style%3A%20Caved%20Time&search[stringTraits][0][values][12]=Style%3A%20Conceptual%20Speculation&search[stringTraits][0][values][13]=Style%3A%20Escape&search[stringTraits][0][values][14]=Style%3A%20Gotta%20Mint%20%27em%20All&search[stringTraits][0][values][15]=Style%3A%20Huntress&search[stringTraits][0][values][16]=Style%3A%20Metadata&search[stringTraits][0][values][17]=Artist%3A%20ZacKenny&search[stringTraits][0][values][18]=Style%3A%20Cookie%2C%20Monster&search[stringTraits][0][values][19]=Style%3A%20Ego%20Death&search[stringTraits][0][values][20]=Style%3A%20Love%20or%20Death%20by%20Daylight&search[stringTraits][0][values][21]=Style%3A%20Remain%20Complacent&search[stringTraits][0][values][22]=Style%3A%20Sailing%20Ship&search[stringTraits][0][values][23]=Style%3A%20Sean&search[stringTraits][0][values][24]=Style%3A%20Threshold`,
      },
    },
  },
  {
    name: "Uniqly Christmas Madness",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET}uniqlychristmas`,
      },
    },
  },
  {
    name: "CyberKongz",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=CyberKongz&search[stringTraits][0][values][0]=myoo`,
      },
    },
  },

  // ADDITIONAL STORES
  {
    name: "Cyber Kongz Play&Kollect",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Edition&search[stringTraits][0][values][0]=Play%26Kollect`,
      },
    },
  },
  {
    name: "Encyklopedia Kryptowalut",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Encyklopedia%20Kryptowalut&search[stringTraits][0][values][0]=Collection`,
      },
    },
  },
  {
    name: "Jarzombek",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Jarzombek&search[stringTraits][0][values][0]=Collection`,
      },
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON}?search[stringTraits][0][name]=Jarzombek&search[stringTraits][0][values][0]=Collection`,
      },
    },
  },
  {
    name: "NFTHub",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=NFTHub&search[stringTraits][0][values][0]=Style%3A%20Trash%20a%20Hallelujah&search[stringTraits][0][values][1]=Artist%3A%20BruceTheGoose&search[stringTraits][0][values][2]=Artist%3A%20DrawberryMilkTea&search[stringTraits][0][values][3]=Style%3A%20I%20Heart%20NFT&search[stringTraits][0][values][4]=Artist%3A%20Marcotic&search[stringTraits][0][values][5]=Style%3A%20Diamond%20Hands&search[stringTraits][0][values][6]=Style%3A%20Killer%20Bun&search[stringTraits][0][values][7]=Artist%3A%20Magusz%20%26%20Natural%20Warp&search[stringTraits][0][values][8]=Artist%3A%20hlichaa&search[stringTraits][0][values][9]=Style%3A%20Dragons%20%22N%22%20Kings&search[stringTraits][0][values][10]=Artist%3A%20Magusz&search[stringTraits][0][values][11]=Style%3A%20Caved%20Time&search[stringTraits][0][values][12]=Style%3A%20Conceptual%20Speculation&search[stringTraits][0][values][13]=Style%3A%20Escape&search[stringTraits][0][values][14]=Style%3A%20Gotta%20Mint%20%27em%20All&search[stringTraits][0][values][15]=Style%3A%20Huntress&search[stringTraits][0][values][16]=Style%3A%20Metadata&search[stringTraits][0][values][17]=Artist%3A%20ZacKenny&search[stringTraits][0][values][18]=Style%3A%20Cookie%2C%20Monster&search[stringTraits][0][values][19]=Style%3A%20Ego%20Death&search[stringTraits][0][values][20]=Style%3A%20Love%20or%20Death%20by%20Daylight&search[stringTraits][0][values][21]=Style%3A%20Remain%20Complacent&search[stringTraits][0][values][22]=Style%3A%20Sailing%20Ship&search[stringTraits][0][values][23]=Style%3A%20Sean&search[stringTraits][0][values][24]=Style%3A%20Threshold`,
      },
    },
  },
  {
    name: "Uniqly Collection",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS}?search[stringTraits][0][name]=Uniqly.io&search[stringTraits][0][values][0]=Collection`,
      },
    },
  },
  {
    name: "Uniqly Genesis",
    url: {
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET}uniqly-genesis`,
      },
    },
  },
  {
    name: "Uniqly Generator",
    url: {
      [CHAIN_IDS.MAIN]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET}uniqly-generator`,
      },
      [CHAIN_IDS.POLYGON_MAINNET]: {
        base: `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET}uniqly-generator-polygon`,
      },
    },
  },
];
