import { FC } from "react";

import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

import { COLOR } from "./config";

import styles from "./styles";

interface BoxLabelProps {
  color?: COLOR;
  children: JSX.Element | JSX.Element[] | string | null;
  show?: boolean;
  padding?: number;
  variant?: TypographyProps["variant"];
  boxSx?: SxProps<Theme>;
}

const BoxLabel: FC<BoxLabelProps> = ({
  color = COLOR.PRIMARY,
  children,
  show = true,
  padding = 1,
  variant = "h3",
  boxSx = [],
}) => {
  if (!show) {
    return null;
  }
  return (
    <Box
      sx={[
        color === COLOR.PRIMARY ? styles.primary : styles.secondary,
        styles.box,
        ...(Array.isArray(boxSx) ? boxSx : [boxSx]),
      ]}
      p={padding}
    >
      <Typography variant={variant} align="center" fontWeight={600}>
        {children}
      </Typography>
    </Box>
  );
};

export default BoxLabel;
