import find from "lodash/find";

import { collections } from "./collections";

import { CHAIN_IDS } from "enums/chainIds";

import { EXTERNAL_ROUTES } from "config/routes";

import { isChainIdSupported } from "utils/blockchain";

class OpenSeaLinksFactory {
  private static getCollectionBaseUrl({
    chainId,
  }: {
    chainId: CHAIN_IDS;
    isCreatedByForm?: boolean;
  }): string {
    if (
      [CHAIN_IDS.POLYGON_MAINNET, CHAIN_IDS.POLYGON_TESTNET].includes(chainId)
    ) {
      return EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS_POLYGON;
    }

    return EXTERNAL_ROUTES.OPENSEA_UNIQLY_COLLECTIONS;
  }

  static getCollectionUrlByName({
    chainId,
    collectionName,
    bundleName,
    isCreatedByForm,
    contractAddress,
  }: {
    chainId: CHAIN_IDS;
    collectionName: string;
    bundleName?: string;
    isCreatedByForm?: boolean;
    contractAddress?: string;
  }): string {
    if (isCreatedByForm) {
      return `${EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET_POLYGON}${contractAddress}`;
    }
    const collectionUrls = find(collections, { name: collectionName });

    if (!collectionUrls) {
      return this.getCollectionBaseUrl({ chainId });
    }

    const chainCollectionUrl =
      collectionUrls.url[chainId] ||
      collectionUrls.url[Object.keys(collectionUrls.url)[0]];

    const bundle =
      bundleName &&
      collectionUrls.bundles?.find((bundle) => bundle.name === bundleName);

    if (!bundle) {
      return `${chainCollectionUrl.base}${chainCollectionUrl.all || ""}`;
    }
    return `${chainCollectionUrl.base}${bundle.value || ""}`;
  }

  static getOpenseaUrl(chainId: CHAIN_IDS): string {
    if (!isChainIdSupported(chainId)) {
      return "";
    }

    if ([CHAIN_IDS.MAIN, CHAIN_IDS.SEPOLIA].includes(chainId)) {
      return EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET;
    }

    return EXTERNAL_ROUTES.OPENSEA_UNIQLY_ASSET_POLYGON;
  }
}

export default OpenSeaLinksFactory;
