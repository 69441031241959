import { Theme } from "@mui/material/styles";
import { alpha, SystemStyleObject } from "@mui/system";

const dropsTileStyles = {
  overflow: (): SystemStyleObject<Theme> => ({
    overflow: "hidden",
  }),
  lineClamp: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textAlign: "left",
  },
  content: (theme: Theme): SystemStyleObject<Theme> => ({
    p: 1,
    backgroundImage: `linear-gradient(180deg, ${alpha(
      theme.palette.secondary.main,
      0.6,
    )}, ${alpha(theme.palette.secondary.dark, 0.5)})`,

    [theme.breakpoints.up("lg")]: {
      p: 2,
    },
  }),
  titleWrapper: (): SystemStyleObject<Theme> => ({
    display: "flex",
    alignItems: "center",
    mb: 1,
    position: "relative",
  }),
  title: (theme: Theme): SystemStyleObject<Theme> => ({
    textShadow: `0 0 3px ${theme.palette.text.secondary}`,

    [theme.breakpoints.up("sm")]: {
      fontSize: "24px !important",
    },

    [theme.breakpoints.up("lg")]: {
      fontSize: "28px !important",
    },
  }),
  backgroundImage: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transform: "scale(2)",
    filter: "blur(30px) brightness(1.4)",
  }),
  tooltipIcon: (theme: Theme): SystemStyleObject<Theme> => ({
    filter: `drop-shadow(0 0 10px ${alpha(
      theme.palette.background.default,
      0.75,
    )})`,
  }),
  chain: (theme: Theme): SystemStyleObject<Theme> => ({
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    textShadow: `0 0 3px ${theme.palette.text.secondary}`,
  }),
};

export default dropsTileStyles;
