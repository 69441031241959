import { CHAIN_IDS } from "enums/chainIds";

import { getEthereumChainId, getPolygonChainId } from "utils/blockchain";

import OpenSeaLinksFactory from "services/opensea/OpenSeaLinksFactory";

const getDestinationChainId = (chainIds: CHAIN_IDS[]): CHAIN_IDS => {
  if (
    chainIds &&
    !chainIds.includes(getPolygonChainId()) &&
    chainIds.includes(getEthereumChainId())
  ) {
    return getEthereumChainId();
  }

  return getPolygonChainId();
};

const useOpenseaLink = ({
  collectionName,
  bundleName,
  chainIds,
  isCreatedByForm = false,
  contractAddress,
}: {
  collectionName: string;
  bundleName?: string;
  chainIds: CHAIN_IDS[];
  isCreatedByForm?: boolean;
  contractAddress?: string;
}): { openseaUrl: string } => {
  const destinationChainId = getDestinationChainId(chainIds);

  const openseaUrl = OpenSeaLinksFactory.getCollectionUrlByName({
    chainId: destinationChainId,
    collectionName,
    bundleName,
    isCreatedByForm,
    contractAddress,
  });

  return { openseaUrl };
};

export default useOpenseaLink;
