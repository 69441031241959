import { FC } from "react";

import NextImage from "next/legacy/image";
import { isFuture } from "date-fns";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CustomLink from "components/CustomLink";
import BoxLabel from "components/BoxLabel";
import Counter from "components/Counter";
import { COLOR } from "components/BoxLabel/config";

import useOpenseaLink from "hooks/useOpenseaLink";

import { Metadata } from "interfaces/Metadata.interface";

import { APP_ROUTES } from "config/routes";

import { Client } from "services/store/Store.interface";

import styles from "./styles";

interface DropTileProps {
  client: Client & {
    bundles?: {
      metadata?: Metadata;
    }[];
  };
  isOpenseaRedirect?: boolean;
  showSoldOut?: boolean;
  showTimer?: boolean;
}

const StoreTile: FC<DropTileProps> = ({
  client,
  isOpenseaRedirect = false,
  showSoldOut = true,
  showTimer = true,
}) => {
  const { openseaUrl } = useOpenseaLink({
    collectionName: client.name,
    chainIds: client.chainIds,
    isCreatedByForm: !!client.isCreatedByForm,
    contractAddress: client.mintContractAddress,
  });

  return (
    <Box position="relative" height="100%">
      <Box width="100%" height="100%" position="relative" sx={styles.overflow}>
        <CustomLink
          href={
            isOpenseaRedirect
              ? openseaUrl
              : client.url !== "christmas"
              ? `${APP_ROUTES.STORES}/${client.url}`
              : `/${client.url}`
          }
          target={isOpenseaRedirect && openseaUrl ? "_blank" : "_self"}
          rel="noopener noreferrer"
          noDecoration
          noDecorationOnHover
          sx={{ overflow: "hidden" }}
        >
          <Box
            display="flex"
            justifyContent="end"
            flexDirection="column"
            sx={styles.content}
          >
            <Box sx={styles.backgroundImage}>
              {(client.tileImage || client.avatar) && (
                <NextImage
                  src={client.tileImage || client.avatar}
                  layout="fill"
                />
              )}
            </Box>

            <Box sx={styles.titleWrapper}>
              <Typography
                component="h4"
                variant="h2"
                sx={[styles.lineClamp, styles.title]}
                textTransform="uppercase"
              >
                {client.name}
              </Typography>
            </Box>

            <Box position="relative" sx={{ aspectRatio: "1" }}>
              {(client.tileImage || client.avatar) && (
                <NextImage
                  src={client.tileImage || client.avatar}
                  width={400}
                  height={400}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
            <BoxLabel show={showSoldOut && client.isSoldOut}>SOLD OUT</BoxLabel>
          </Box>

          {client.startSellingDate && (
            <Box sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <BoxLabel
                color={COLOR.SECONDARY}
                show={showTimer && isFuture(new Date(client.startSellingDate))}
              >
                <>
                  <Counter isPlain time={new Date(client.startSellingDate)} />{" "}
                  left
                </>
              </BoxLabel>
            </Box>
          )}
        </CustomLink>
      </Box>
    </Box>
  );
};

export default StoreTile;
