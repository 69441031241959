import { FC } from "react";
import { useDispatch } from "react-redux";

import { setAuthModalOpen, setAuthModalTab } from "redux/slices/metamask";

import CustomButton, { CustomButtonProps } from "components/CustomButton";

type SignInButtonProps = {
  defaultTab?: 0 | 1;
  buttonProps?: CustomButtonProps;
};

const SignInButton: FC<SignInButtonProps> = ({
  defaultTab = 0,
  buttonProps = {},
}) => {
  const dispatch = useDispatch();

  const openAuthModal = () => {
    dispatch(setAuthModalTab(defaultTab));
    dispatch(setAuthModalOpen(true));
  };

  return (
    <CustomButton
      onClick={openAuthModal}
      {...buttonProps}
      sx={[
        ...(Array.isArray(buttonProps.sx) ? buttonProps.sx : [buttonProps.sx]),
      ]}
    >
      SIGN IN
    </CustomButton>
  );
};

export default SignInButton;
