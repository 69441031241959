import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const heroStyles = {
  box: (): SystemStyleObject<Theme> => ({
    width: "100%",
  }),
  primary: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.main,
  }),
  secondary: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    color: theme.palette.text.primary,
  }),
};

export default heroStyles;
