import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const dividerStyles = {
  wrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "fixed",
    bottom: "32px",
    right: "32px",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "50%",
    width: 37,
    height: 37,
    zIndex: 99998,
    cursor: "pointer",
    backgroundColor: theme.palette.background.default,
  }),
};

export default dividerStyles;
