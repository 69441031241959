import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogoIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="3082"
    height="1024"
    viewBox="0 0 3082 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M1387.36 643.233L1266.8 683.174C1261.48 685.075 1255.39 685.075 1250.07 683.174L1129.89 643.233C1118.86 639.429 1111.63 629.539 1111.63 617.747V436.683C1111.63 432.879 1112.78 429.075 1114.68 425.652L1115.44 424.13C1125.71 407.013 1150.43 407.013 1161.07 424.13L1161.84 425.652C1163.74 429.075 1164.88 432.879 1164.88 436.683V578.948C1164.88 590.359 1172.1 600.63 1183.13 604.053L1250.07 626.496C1255.39 628.398 1261.48 628.398 1267.18 626.496L1334.11 604.053C1345.14 600.249 1352.37 590.359 1352.37 578.948V436.683C1352.37 432.879 1353.51 429.075 1355.41 425.652L1356.17 424.13C1366.44 407.013 1391.16 407.013 1401.81 424.13L1402.57 425.652C1404.47 429.075 1405.61 432.879 1405.61 436.683V617.747C1405.61 629.539 1398.39 639.809 1387.36 643.233H1387.36Z"
        fill="currentColor"
      />
      <path
        d="M1731.53 649.319L1730.77 650.841C1720.5 667.958 1695.78 667.958 1685.14 650.841L1684.38 649.319C1682.47 645.896 1681.33 642.092 1681.33 638.288V496.023C1681.33 484.612 1674.11 474.341 1663.08 470.918L1596.14 448.475C1590.82 446.573 1584.74 446.573 1579.03 448.475L1512.1 470.918C1501.07 474.722 1493.84 484.612 1493.84 496.023V638.288C1493.84 642.092 1492.7 645.896 1490.8 649.319L1490.04 650.841C1479.77 667.958 1455.05 667.958 1444.4 650.841L1443.64 649.319C1441.74 645.896 1440.6 642.092 1440.6 638.288V457.224C1440.6 445.812 1447.83 435.542 1458.85 431.738L1579.41 391.798C1584.74 389.896 1590.82 389.896 1596.14 391.798L1716.7 431.738C1727.73 435.542 1734.96 445.432 1734.96 457.224V638.288C1734.58 642.092 1733.44 645.896 1731.53 649.319H1731.53Z"
        fill="currentColor"
      />
      <path
        d="M1796.19 403.97C1781.35 403.97 1769.56 392.178 1769.56 377.343C1769.56 362.508 1781.35 350.716 1796.19 350.716C1811.02 350.716 1822.81 362.508 1822.81 377.343C1822.81 392.178 1811.02 403.97 1796.19 403.97ZM1822.81 457.604V638.288C1822.81 642.092 1821.67 645.896 1819.76 649.319L1819 650.841C1808.74 667.958 1784.02 667.958 1773.37 650.841L1772.61 649.319C1770.7 645.896 1769.56 642.092 1769.56 638.288V457.604C1769.56 442.769 1781.35 430.977 1796.19 430.977C1811.02 430.597 1822.81 442.769 1822.81 457.604V457.604Z"
        fill="currentColor"
      />
      <path
        d="M2148.73 729.581L2147.97 731.102C2137.7 748.219 2112.98 748.219 2102.33 731.102L2101.57 729.581C2099.67 726.157 2098.53 722.353 2098.53 718.549V654.645L2013.34 683.173C2008.02 685.075 2001.93 685.075 1996.61 683.173L1876.43 643.233C1865.4 639.429 1858.18 629.539 1858.18 617.747V456.844C1858.18 445.432 1865.4 435.162 1876.43 431.358L1996.99 391.417C2002.31 389.515 2008.4 389.515 2013.72 391.417L2134.28 431.358C2145.31 435.162 2152.53 445.052 2152.53 456.844V718.169C2151.77 722.353 2151.01 726.157 2148.73 729.581V729.581ZM1996.23 448.095L1929.29 470.538C1918.26 474.341 1911.04 484.231 1911.04 495.643V578.948C1911.04 590.359 1918.26 600.63 1929.29 604.053L1996.23 626.496C2001.55 628.398 2007.64 628.398 2013.34 626.496L2080.27 604.053C2091.3 600.249 2098.53 590.359 2098.53 578.948V495.643C2098.53 484.232 2091.3 473.961 2080.27 470.538L2013.34 448.095C2008.02 446.193 2001.93 446.193 1996.23 448.095Z"
        fill="currentColor"
      />
      <path
        d="M2237.34 649.319L2236.58 650.841C2226.31 667.958 2201.59 667.958 2190.94 650.841L2190.18 649.319C2188.28 645.896 2187.14 642.092 2187.14 638.288V276.16C2187.14 272.356 2188.28 268.553 2190.18 265.129L2190.94 263.608C2201.21 246.49 2225.93 246.49 2236.58 263.608L2237.34 265.129C2239.24 268.553 2240.38 272.356 2240.38 276.16V638.288C2240.38 642.092 2239.24 645.896 2237.34 649.319V649.319Z"
        fill="currentColor"
      />
      <path
        d="M2557.94 723.114L2437.38 763.435C2432.06 765.337 2425.97 765.337 2420.27 763.435L2300.09 723.114C2286.02 718.549 2278.79 703.334 2283.36 689.26C2287.92 675.185 2303.13 667.578 2317.2 672.523L2420.65 707.138C2425.97 709.04 2432.06 709.04 2437.76 707.138L2522.95 678.609V654.645L2437.76 683.174C2432.44 685.075 2426.35 685.075 2421.03 683.174L2300.85 643.233C2289.82 639.429 2282.6 629.539 2282.6 617.747V436.683C2282.6 432.879 2283.74 429.076 2285.64 425.652L2286.4 424.13C2296.67 407.013 2321.39 407.013 2332.04 424.13L2332.8 425.652C2334.7 429.076 2335.84 432.879 2335.84 436.683V578.948C2335.84 590.359 2343.07 600.63 2354.09 604.053L2421.03 626.496C2426.35 628.398 2432.44 628.398 2438.14 626.496L2505.08 604.053C2516.1 600.249 2523.33 590.359 2523.33 578.948V436.683C2523.33 432.879 2524.47 429.076 2526.37 425.652L2527.13 424.13C2537.4 407.013 2562.12 407.013 2572.77 424.13L2573.53 425.652C2575.43 429.076 2576.57 432.879 2576.57 436.683V697.628C2576.19 709.04 2568.97 719.31 2557.94 723.114L2557.94 723.114Z"
        fill="currentColor"
      />
      <path
        d="M2637.8 643.994C2622.97 643.994 2611.18 632.202 2611.18 617.367C2611.18 602.532 2622.97 590.74 2637.8 590.74C2652.63 590.74 2664.42 602.532 2664.42 617.367C2664.42 632.202 2652.63 643.994 2637.8 643.994Z"
        fill="currentColor"
      />
      <path
        d="M2726.41 403.97C2711.58 403.97 2699.79 392.178 2699.79 377.343C2699.79 362.508 2711.58 350.716 2726.41 350.716C2741.25 350.716 2753.04 362.508 2753.04 377.343C2753.04 392.178 2740.87 403.97 2726.41 403.97ZM2753.04 457.604V638.288C2753.04 642.092 2751.89 645.896 2749.99 649.319L2749.23 650.841C2738.96 667.958 2714.24 667.958 2703.6 650.841L2702.84 649.319C2700.93 645.896 2699.79 642.092 2699.79 638.288V457.604C2699.79 442.769 2711.58 430.977 2726.41 430.977C2740.87 430.597 2753.04 442.769 2753.04 457.604V457.604Z"
        fill="currentColor"
      />
      <path
        d="M3063.75 643.233L2943.19 683.174C2937.86 685.075 2931.78 685.075 2926.45 683.174L2805.9 643.233C2794.87 639.429 2787.64 629.539 2787.64 617.747V456.844C2787.64 445.432 2794.87 435.162 2805.9 431.358L2926.45 391.417C2931.78 389.515 2937.86 389.515 2943.19 391.417L3063.75 431.358C3074.77 435.162 3082 445.052 3082 456.844V617.747C3082 629.539 3074.39 639.809 3063.75 643.233ZM3028.38 578.948V495.643C3028.38 484.231 3021.15 473.961 3010.12 470.538L2943.19 448.095C2937.86 446.193 2931.78 446.193 2926.07 448.095L2859.14 470.538C2848.11 474.341 2840.89 484.231 2840.89 495.643V578.948C2840.89 590.359 2848.11 600.63 2859.14 604.053L2926.07 626.496C2931.4 628.398 2937.48 628.398 2943.19 626.496L3010.12 604.053C3021.15 600.63 3028.38 590.359 3028.38 578.948Z"
        fill="currentColor"
      />
      <path
        d="M52.4822 290.235L6.46519 264.749C2.28183 274.259 0 284.529 0 295.18V728.44C0 739.091 2.28183 749.361 6.46519 758.871L52.4822 733.765V290.235V290.235Z"
        fill="currentColor"
      />
      <path
        d="M466.255 50.5914V0C456.748 1.14116 447.62 4.18424 438.873 8.74887L39.5518 228.612C30.4245 233.557 22.4381 240.404 16.3531 248.392L61.9899 273.498L466.255 50.5914Z"
        fill="currentColor"
      />
      <path
        d="M889.536 273.498L935.173 248.392C929.088 240.404 921.101 233.557 911.974 228.612L512.272 8.74887C503.906 4.18424 494.398 1.14116 485.271 0V50.211L889.536 273.498V273.498Z"
        fill="currentColor"
      />
      <path
        d="M945.06 264.749L899.043 289.855V734.146L945.06 759.251C949.244 749.742 951.526 739.471 951.526 728.82V295.56C951.526 284.91 949.244 274.259 945.06 264.749Z"
        fill="currentColor"
      />
      <path
        d="M485.271 973.789V1024C494.778 1022.86 503.906 1019.82 512.653 1015.25L912.354 795.388C921.482 790.443 929.468 783.596 935.553 775.608L889.916 750.502L485.271 973.789L485.271 973.789Z"
        fill="currentColor"
      />
      <path
        d="M61.9899 750.502L16.3532 775.607C22.4381 783.596 30.4245 790.443 39.5519 795.388L439.254 1015.25C447.62 1019.82 457.128 1022.86 466.636 1024V973.789L61.9899 750.502Z"
        fill="currentColor"
      />
      <path
        d="M475.763 66.9482L71.4976 290.235V734.146L475.763 957.433L880.028 734.146V290.235L475.763 66.9482ZM458.269 506.675V601.771C458.269 623.073 445.338 642.473 425.562 650.841L327.824 691.923C321.359 694.586 314.133 696.107 307.287 696.107C300.061 696.107 293.216 694.586 286.751 691.923L189.012 650.841C169.236 642.473 156.306 623.073 156.306 601.771V412.719C156.306 399.026 167.335 387.994 181.026 387.994C194.717 387.994 205.746 399.026 205.746 412.719V601.771C205.746 603.293 206.506 604.815 208.027 605.195L305.766 646.657C306.527 647.037 307.668 647.037 308.808 646.657L406.547 605.575C408.068 604.815 408.829 603.673 408.829 602.152V507.055L409.59 413.1C409.59 399.406 420.618 388.375 434.31 388.375C448.001 388.375 459.029 399.786 459.029 413.1L458.269 506.675V506.675ZM795.6 674.045C795.6 687.739 784.571 698.77 770.88 698.77C757.189 698.77 746.16 687.739 746.16 674.045V599.109L664.775 632.583C651.845 637.908 637.393 637.908 624.463 632.583L526.724 592.262C506.568 583.893 493.637 564.494 493.637 542.812V428.696C493.637 409.296 503.905 391.798 520.639 382.289L617.997 327.132C633.97 318.003 654.126 318.003 670.099 327.132L767.838 382.289C784.571 391.798 794.84 409.676 794.84 428.696V674.045H795.6H795.6Z"
        fill="currentColor"
      />
      <path
        d="M743.879 425.272L646.14 370.116C645.379 369.736 644.999 369.736 644.238 369.736C643.478 369.736 643.098 369.736 642.337 370.116L544.598 425.272C543.457 426.033 542.697 427.174 542.697 428.696V542.812C542.697 544.333 543.457 545.855 544.979 546.235L642.717 586.556C643.478 586.937 644.619 586.937 645.76 586.556L746.16 545.474V428.696C746.16 427.174 745.4 426.033 743.879 425.272L743.879 425.272Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default LogoIcon;
