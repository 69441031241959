import { FC, useState, useEffect } from "react";

import throttle from "lodash/throttle";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";

import styles from "./styles";

const ScrollToTop: FC = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handler = throttle(() => {
      if (window.scrollY > 700) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    }, 100);

    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return showTopBtn ? (
    <Box sx={styles.wrapper} onClick={goToTop}>
      <KeyboardArrowUpIcon fontSize="large" />
    </Box>
  ) : null;
};

export default ScrollToTop;
